// Fonts family
$system-ui: "system-ui";
$monospace: "monospace";
$poppins: "Poppins";
$roboto: "Roboto";

// Font sizes
$font-12: 12px;
$font-14: 14px;
$font-16: 16px;
$font-18: 18px;
$font-20: 20px;
$font-24: 24px;
$font-32: 32px;

// Font weight
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700; 
$font-weight-800: 800;
$font-weight-bold: bold;

// Margins
$margin-top-10: 10px;
$margin-top-20: 20px;

$margin-bottom-10: 10px;
$margin-bottom-20: 20px;

// Paddings
$padding-top-10: 10px;
$padding-top-20: 20px;

$padding-bottom-10: 10px;
$padding-bottom-20: 20px;
$padding-bottom-60: 60px;

// Border radius
$border-radius-5: 5px;
$border-radius-10: 10px;
$border-radius-20: 20px;