.welcome {
    margin-left: 300px;
    width: 78%;
    height: 100%;
    // background-color: $color-main-background;
    // border-right: 3px solid $border-color;
    // border-left: 3px solid $border-color;
    display: block;
    align-items: center;
    overflow-y: auto;
    position: absolute;

    ul{
        margin: 25px 0 0 0;
        li{
            margin: 15px 0 0 0;
        }
    }

    .content-display{
        margin: 10px 10px 40px 10px;
        color: $text-color;
        font-weight: $font-weight-500;
        padding-bottom: $padding-bottom-60;

        .welcome-top{
            background-color: $color-main-background;
            padding: 40px 0 40px 0;
            border-radius: 20px;
            margin: 20px 0 20px 0;
            .nice-to-meet{
                font-size: 32px;
                padding: 0 24px 12px 200px;
                font-weight: 600;
            }
            .quote{
                padding: 0 24px 12px 200px;
                font-weight: 900;
                .quote-top{
                    font-size: 50px;
                }
                .quote-bottom{
                    font-size: 35px;
                }
            }
            // .developed-by{
            //     font-size:14px;
            //     padding: 0 24px 12px 200px;
            //     .name{
                    
            //     }
            //     .company{
                    
            //     }
            // }
        }

        // .welcome-heading{
        //     margin: 50px 0 20px 0;
            // hr{
            //     border-top: 4px solid $box-background;
            //     border-radius: $border-radius-20;
            //     margin: 15px 0 0 0;
            // }
        // }

        .introPage{
            margin: 25px 485px;
            padding: 12px 10px;
            border-radius: 20px;
            background-color: $next-page-button;
            text-align: center;

            .link{
                color: $color-background;
                text-decoration: none;
                display: inline-block;
                font-weight: $font-weight-800;
            }
        }
    }

}

.welcome::-webkit-scrollbar {
    width: 10px;
}

.welcome::-webkit-scrollbar-thumb {
    background-color: $scrollbar;
    // box-shadow: inset 0 0 15px grey; 
    border-radius: 20px;
}
.welcome::-webkit-scrollbar-track-piece {
    background-color: $scrollbarTrackPiece;
}
