// Common code comes here.
.display-lineBreak { 
    white-space: pre-line;
}

// headings
.heading-1{
    font-size: $font-32;
    font-weight: $font-weight-800;
    margin: 20px 0 0 0;
    hr{
        border-top: 4px solid $box-background;
        border-radius: $border-radius-20;
        margin: 15px 0 0 0;
    }
}
.heading-2{
    font-size: $font-24;
    font-weight: $font-weight-700;
    hr{
        border-radius: 20px;
        border-top: 2px solid #9ba3eb;
        margin: 10px 0 0;
        width: 5%;
    }
}

.heading-3{
    font-size: $font-20;
    font-weight: $font-weight-700;
    margin: 30px 0 10px 0;
    .arrow {
        border: solid $title;
        border-width: 0 3px 3px 0;
        background-color: $title;
        display: inline-block;
        padding: 2px;
        margin-right:5px;
        .right {
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
        }
    }
}
.heading-4{
    font-size: $font-18;
    font-weight: $font-weight-600;
}

// code section
.code{
    background-color:$box-background;
    border-left: 5px solid $box-border;
    padding: 15px;
    border-radius: $border-radius-5;
    color: $black;

    p{
        margin: 0 10px 0 10px;
        font-family: $monospace;
        font-size: $font-16;
        font-weight: $font-weight-600;
    }
}

// quotes section
.quotes{
    background-color:$box-background;
    border-left: 5px solid $box-border;
    padding: 10px;
    color: $black;

    .inner-quotes{
        font-size: $font-24;
        color: $black;
    }

    p{
        margin: -10px 10px 0 10px;
        font-family: $monospace;
        font-size: $font-16;
        font-weight: $font-weight-500;
    }
}

.youtube{
    margin-top: $margin-top-10;
    hr{
        margin: 20px 0 20px 0;
        border-top: 4px solid $box-background;
        border-radius: $border-radius-20;
    }
    .youtube-p{
        text-align: center;
        text-transform: uppercase;
        margin-bottom: $margin-bottom-10;
        font-weight: $font-weight-bold;
        font-size: $font-24;
        color: $box-background;
    }
    .youtube-iframe{
        margin: auto;
        width: 67%;
    }
}

// Pagination
.pagination{
    width:100%;
    text-align: center;
   
    .previous{
      background-color: $next-page-button;
      text-align: center;
      display: inline-block;
      padding: 8px 12px;
      border-radius: 20px;
      font-size: 16px;

      .previousLink{
        color: $color-background;
        text-decoration: none;
        // display: inline-block;
        font-weight: $font-weight-800;
      }
    }
    .next{
      background-color: $next-page-button;
      text-align: center;
      display: inline-block;
      margin-left: 500px;
      padding: 8px 12px;
      border-radius: 20px;
      font-size: 16px;

      .nextLink{
        color: $color-background;
        text-decoration: none;
        // display: inline-block;
        font-weight: $font-weight-800;
      }
    }
}