// New colors
// $box-border: #379683;
// $border-color: #8ee4af;

// $white:#FFFFFF;
// $black:#000000;
// $text-color:#05386b;

// $color-background: #5cdb95;
// // $color-main-background:#cccff2;
// $color-main-background:#5cdb95;

// $title:#edf5e1;
// $box-background: #5cdb95;


// style 2
// $box-border: #4E944F;
// $border-color: #83BD75;

// $white:#FFFFFF;
// $black:#000000;
// $text-color:#000000;

// $color-background: #B4E197;
// $color-main-background:#B4E197;

// $title:#4E944F;
// $box-background: #B4E197;



// style 4
// $box-border: #85586F;
// $border-color: #F8ECD1;

// $white:#FFFFFF;
// $black:#000000;
// $text-color:#85586F;

// $color-background: #F8ECD1;
// $color-main-background:#DEB6AB;

// $title:#85586F;
// $box-background: #DEB6AB;


// style 5
$white:#FFFFFF;
$black:#000000;

$color-background: #DBDFFD;
$color-main-background:#9BA3EB;

$title:#242F9B;
$text-color:#242F9B;

$border-color: #DBDFFD;

$box-border: #242F9B;
$box-background: #9BA3EB;

$next-page-button: #9BA3EB;

$scrollbar:#242F9B;
$scrollbarTrackPiece :#9BA3EB;