@import '../colors.scss';
@import '../components.scss';
@import '../breakPoints/breakPoints.scss';

.navigation {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.5rem 0rem;
    // background-color: $color-main-background;
    color: $text-color;
    box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
    font-weight: $font-weight-bold;
    border-bottom: 3px solid $border-color;


    .brand-name {
        text-decoration: none;
        margin-left: 1rem;
        color: $title;
        font-size: $font-32;
        // -webkit-text-stroke: 0.5px $text-color;
    }

    .navigation-menu {
        margin-left: auto;
    }

    .navigation-menu ul {
        display: flex;
        padding: 0;
    }

    .navigation-menu li {
        list-style-type: none;
        margin: 0 1rem;
    }

    .navigation-menu li a {
        text-decoration: none;
        display: block;
        width: 100%;
        color: $text-color;
    }
    
    .navigation-menu li:hover {
        color: $border-color;
    }

    @include breakPoints("<=phone") {
        .brand-name{
            font-size: 24px;
        }
        .navigation-menu {
            font-size: 14px;
        }
    }
}