@import '../colors.scss';
@import '../components.scss';
@import '../breakPoints/breakPoints.scss';

// this is the website which I found resources to develop the navbar - https://blog.logrocket.com/create-responsive-navbar-react-css/
.sidebar {
    height: 100%;
    width: 20%;
    // padding: 0.5rem 0rem;
    background-color: $color-main-background;
    color: $text-color;
    display: block;
    overflow-y: auto;
    border-right: 3px solid $border-color;
    align-items: center;
    position: absolute;
    padding-bottom: $padding-bottom-60;

    .brand-name {
        text-decoration: none;
        color: $text-color;
        font-size: 1.75rem;
        margin-left: 2.5rem;
        font-weight: $font-weight-bold;
        color: $title;
    }

    .navigation-menu {
        margin-left: auto;
    }
    .navigation-menu ul {
        display: block;
        padding: 0;
        margin: 0;
        margin-bottom:100px;
    }

    li {
        list-style-type: none;
        border-bottom: 3px solid $border-color;
    }
    li a {
        text-decoration: none;
        display: block;
        width: 100%;
        color: $text-color;
        font-weight: $font-weight-600;
        padding: 1rem;
        line-height: 7px;
    }
    
    .active {
        background-color: $border-color;
        padding: 1rem;
        line-height: 7px;
    }
        
    li:hover {
        background-color: $border-color;
    }

    .hamburger_icon{
        display: none;
    }
    .hamburger_close_icon_list{
        display: none;
    }

    @include breakPoints("<=phone") {
        background-color: $color-background;
        box-shadow: none;
        margin: 0 0 0 0;
        height: 35px;
        width: 35px;
        padding-bottom:0;

        .navBar {
            position: relative;
        }
        .navBar button {
            position: fixed;
            left: 40px;
            top: 40px;
            z-index: 10;
            cursor: pointer;
        }
          
        .menuNav {
            list-style: none;
            position: fixed;
            top: 58px;
            background: $color-main-background;
            left: 0;
            bottom: 0;
            height: 100%;
            width: 0;
            overflow-y: scroll;
            max-width: 60%;
            z-index: 9;
        }
          
        .menuNav.showMenu {
            width: 100%;
        }
          
        .sideBar-list {
            display: block;
            text-transform: uppercase;
        }

        li a {
            text-decoration: none;
            display: block;
            width: 87%;
            color: $black;
            font-weight: 600;
            font-size: 12px;
        }

        .hamburger_icon{
            display: inline-block;
            background-color: $black;
            box-shadow: none;
            border: none;
            border-radius: 0px 0px 30px 30px;
            z-index: 10;
        }

        .hamburger_close_icon_list{
            display: inline-block;
            border-bottom: none;
        }

        .hamburger_close_icon {
            color: $black;
            width: 20px;
            height: 40px;
            padding: 0 0 0 195px;
        }

        .hamburger_open_icon{
            color: $white; 
            width: 20px;
            height: 25px;
        }
    }

}

.sidebar::-webkit-scrollbar {
    display: none;
}