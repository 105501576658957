@import '../colors.scss';
@import '../components.scss';
@import './elements.scss';
@import './welcome.scss';
@import '../breakPoints/breakPoints.scss';

.content-wrapper{
    margin-left: 300px;
    width: 60%;
    height: 100%;
    // background-color: $color-main-background;
    // border-right: 3px solid $border-color;
    // border-left: 3px solid $border-color;
    display: block;
    align-items: center;
    overflow-y: auto;
    position: absolute;

    p{
      line-height: 25px;
    }

    a{
      text-decoration: none;
      font-weight: $font-weight-700;
      font-size: 18px;
    }

    ul{
      margin: 25px 0 0 0;
      li{
          margin: 15px 0 0 0;
      }
    }

    .content-display{
        margin: 10px 10px 70px 10px;
        color: $text-color;
        font-weight: $font-weight-500;
        padding-bottom: $padding-bottom-60;
    }
  
    @include breakPoints("<=phone") {
      display: none;
    }
}

.content-wrapper::-webkit-scrollbar {
  width: 10px;
}

.content-wrapper::-webkit-scrollbar-thumb {
  background-color: $scrollbar;
  border-radius: 20px;
}
.content-wrapper::-webkit-scrollbar-track-piece {
  background-color: $scrollbarTrackPiece;
}


.right-content-wrapper {
    margin-left: 1180px;
    width: 18%;
    height: 110%;
    display: block;
    position: absolute;
    overflow-y: auto;
    border-left: 3px solid $border-color;
    background-color: $color-main-background;

    .right-content-display{
        // margin: 10px 10px 0 10px;
        color: $text-color;
        font-weight: $font-weight-500;
        padding-bottom: $padding-bottom-60;
        margin-right: auto;

        // .headding{
        //     text-align: center;
        //     font-weight: $font-weight-600;
        //     border-bottom: 3px solid $border-color;
        //     padding: 10px;
        // }
        
        ul {
            display: block;
            padding: 0;
            margin: 0;
            margin-bottom:100px;

            li {
                list-style-type: none;
                padding: 1rem;
                border-bottom: 3px solid $border-color;
                // line-height: 15px;
            }

            li a {
                text-decoration: none;
                display: block;
                width: 100%;
                color: $text-color;
                font-weight: $font-weight-600;
            }

            li:hover {
                background-color: $border-color;
            }
        }
    
    }

    @include breakPoints("<=phone") {
      display: none;
    }
}

.right-content-wrapper::-webkit-scrollbar {
  display: none;
}